var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"first-section page404"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6 offset-md-3"},[(
                            _vm.simpleData &&
                                _vm.simpleData.result &&
                                (_vm.simpleData.result.e === 'SUCCESS' ||
                                    _vm.simpleData.result.is_success)
                        )?_c('div',[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Sikeres megrendelés"))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.simpleData.result_formatted)}})]):_c('div',[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Sikertelen megrendelés!"))+" ")]),(
                                _vm.simpleData &&
                                    _vm.simpleData.result &&
                                    _vm.simpleData.result.e === 'TIMEOUT'
                            )?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t( "Túllépte a tranzakció maximális idő limitét." ))+" ")]):_vm._e(),(
                                _vm.simpleData &&
                                    _vm.simpleData.result &&
                                    _vm.simpleData.result.e === 'CANCEL'
                            )?_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t("Megszakított fizetés."))+" ")]):_vm._e(),(
                                _vm.simpleData.result &&
                                    _vm.simpleData.result.e !== 'CANCEL'
                            )?_c('p',{staticClass:"text-danger",domProps:{"innerHTML":_vm._s(_vm.simpleData.result_formatted)}}):_vm._e()]),_c('router-link',{staticClass:"btn btn-primary mt-5",attrs:{"to":{ name: 'Home' }}},[_vm._v("Vissza a főoldalra ")])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }