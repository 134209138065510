<template>
    <div>
        <section class="first-section page404">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div
                            v-if="
                                simpleData &&
                                    simpleData.result &&
                                    (simpleData.result.e === 'SUCCESS' ||
                                        simpleData.result.is_success)
                            "
                        >
                            <h2 class="title">
                                {{ $t("Sikeres megrendelés") }}
                            </h2>
                            <p v-html="simpleData.result_formatted"></p>
                        </div>
                        <div v-else>
                            <h2 class="title">
                                {{ $t("Sikertelen megrendelés!") }}
                            </h2>
                            <h2
                                class="title"
                                v-if="
                                    simpleData &&
                                        simpleData.result &&
                                        simpleData.result.e === 'TIMEOUT'
                                "
                            >
                                {{
                                    $t(
                                        "Túllépte a tranzakció maximális idő limitét."
                                    )
                                }}
                            </h2>
                            <h2
                                class="title"
                                v-if="
                                    simpleData &&
                                        simpleData.result &&
                                        simpleData.result.e === 'CANCEL'
                                "
                            >
                                {{ $t("Megszakított fizetés.") }}
                            </h2>
                            <p
                                class="text-danger"
                                v-if="
                                    simpleData.result &&
                                        simpleData.result.e !== 'CANCEL'
                                "
                                v-html="simpleData.result_formatted"
                            ></p>
                        </div>
                        <router-link
                            :to="{ name: 'Home' }"
                            class="btn btn-primary mt-5"
                            >Vissza a főoldalra
                        </router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "PaymentFinish",
    data() {
        return {
            simpleData: {
                result: {
                    e: null
                }
            }
        };
    },
    computed: {
        url: function() {
            return process.env.NODE_ENV === "production"
                ? `${process.env.VUE_APP_API_URL}`
                : "";
        }
    },
    beforeMount() {
        const self = this;
        self.axios
            .post(`${self.url}/backref`, self.$route.query)
            .then(response => {
                self.simpleData = response.data;
                localStorage.setItem("cart_uuid", response.data.cart.uuid);
            })
            .catch(err => {
                console.log(err);
                // this.$router.push({ name: 'NotFound' })
            })
            .finally(() => {
                self.$eventHub.$emit("updateCart", true);
            });
    }
};
</script>

<style scoped></style>
